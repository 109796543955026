import React from 'react';
import styled from 'styled-components';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Extension from '@material-ui/icons/Extension';
import Button from 'components/CustomButtons/Button';

import Payment from '@material-ui/icons/Payment';
import PlayArrow from '@material-ui/icons/PlayArrow';
import LocalActivity from '@material-ui/icons/LocalActivity';
import Search from '@material-ui/icons/Search';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import featuresStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js';

import cineplex from 'assets/img/cineplex3d/Cineplex.png';

import { history } from '../../../index';

const CenterDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 4rem;
`;

const useStyles = makeStyles(featuresStyle);

export default function CineplexSection({ ...rest }) {
	console.log('history', history);

	const handleClick = React.useCallback(() => {
		console.log('history push');
		history.push('case-study');
	}, []);

	const classes = useStyles();
	console.log({ classes });
	return (
		<div className="cd-section" {...rest} id="case-study">
			<div className={classes.container}>
				<div className={classes.features4} style={{ justifyContent: 'center' }}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={8}
							md={8}
							className={classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter}
						>
							<h2 className={classes.title}>Case Study: Cineplex-App</h2>
							<h5 className={classes.description}>
								Unser bisher wichtigstes Projekt: Die komplette Neuentwicklung der App für die
								größte deutsche Kinokette: <b>Cineplex Deutschland</b>.
							</h5>
							<h5 className={classes.description}>
								Wir stehen unseren Kunden als transparenter Partner zur Seite - egal ob im
								Kickoff-Workshop im Postdamer Office, beim Sprint-Review im Video Call oder vor Ort,
								beim Handover an den Kunden.
							</h5>
						</GridItem>
					</GridContainer>
					<GridContainer style={{ justifyContent: 'center' }}>
						<GridItem xs={12} sm={12} md={12} lg={3} className={classes.mlAutoCineplex}>
							<InfoArea
								icon={LocalActivity}
								title="Bonuspunkte sammeln"
								description="Mit Hilfe der digitalen Kundenkarte werden Kunden gebunden. Über diese erhalten Filmfans wertvolle Prämien, die sich in der echten Welt einlösen und genießen lassen."
								iconColor="secondary"
							/>
							<InfoArea
								icon={PlayArrow}
								title="Trailer anschauen"
								description="Mit Videos transportiert man effektiv Botschaften. Eine Funktionalität zum Abspielen von Trailern darf da natürlich nicht fehlen."
								iconColor="secondary"
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={12} lg={4} onClick={handleClick}>
							<div className={classes.phoneContainer}>
								<img src={cineplex} alt="..." />
							</div>
							<CenterDiv>
								<Button color="danger" size="lg" onClick={handleClick} style={{whiteSpace: 'normal',wordWrap: 'break-word'}}>
									Hier mehr über die Cineplex-Case-Study lesen
								</Button>
							</CenterDiv>
						</GridItem>
						<GridItem xs={12} sm={12} md={12} lg={3} className={classes.mrAutoCineplex}>
							<InfoArea
								icon={Payment}
								title="Tickets buchen"
								description="Besonders tricky: Die Cineplex-Ticketbuchung sollte als Webview eingebunden werden, ohne dass der Nutzer das merkt. Eine große Herausforderung an UI, UX und Technologie."
								iconColor="secondary"
							/>
							<InfoArea
								icon={Search}
								title="Entdecken"
								description="Die Cineplex-App richtet ein Hauptaugenmerk darauf, dass Nutzer möglichst viele interessante Filme entdecken. Eine Kino-App für das Netflix-Zeitalter."
								iconColor="secondary"
							/>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
