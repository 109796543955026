import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
// @material-ui/icons
import Share from '@material-ui/icons/Share';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
// core components
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import headersStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js';
import dg1 from 'assets/img/dg1.jpg';
import dg2 from 'assets/img/dg2.jpg';
import dg3 from 'assets/img/dg3.jpg';
import { whiteColor } from 'assets/jss/material-kit-pro-react';

const useStyles = makeStyles(headersStyle);

export default function ReferenceProjectsSection({ ...rest }) {
	const classes = useStyles();
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
	};

	const StyledP = styled.p`
		margin-bottom: 30;
		display: inline;
		padding: 0.5em 0;
		background-color: rgba(0, 0, 0, 0.6);
		line-height: 2;
		box-shadow: 1em 0 5px rgba(0, 0, 0, 0.6), -1em 0 5px rgba(0, 0, 0, 0.6);
		box-decoration-break: clone;
		@media (max-width: 768px) {
			font-size: 0.78rem;
		}
	`;

	const StyledHeader1 = styled.h1`
		@media (max-width: 768px) {
			font-size: 3rem;
		}
		@media (max-width: 400px) {
			font-size: 2.5rem;
		}
	`;
	const styledHeader2 = styled.h2``;

	return (
		// we've set the className to cd-section so we can make smooth scroll to it
		<div className="cd-section" {...rest}>
			<div className={classes.sectionBlank} id="blanksection" />

			<Carousel {...settings}>
				{/* Carousel 1 START */}
				<div>
					<div className={classes.pageHeader} style={{ backgroundImage: `url("${dg1}")` }}>
						<div className={classes.container} style={{}}>
							<GridContainer style={{ margin: '0 50px' }}>
								<GridItem xs={12} sm={10} md={7}>
									<StyledHeader1 className={classes.title}>Referenzen</StyledHeader1>
									<div style={{ color: whiteColor, marginBottom: '30px' }}>
										<h2>Cinuru</h2>
										<StyledP>
											Neben unsereren Agentur-Tätigkeiten entwickeln wir unsere eigene App: Cinuru!
											Cinuru ermöglicht es mittelständigen Kinos ihre Kundenbeziehung zu
											digitalisieren.
										</StyledP>
										<br />
										<StyledP>
											Die App enthält neben Filminfos, Kinoprogramm und Ticketbuchungsmöglichkeiten
											auch ein digitales Bonusprogramm.
										</StyledP>
										<br />
										<StyledP>
											Das Ziel: Nutzer sollen nie mehr einen Film im Kino verpassen. Deshalb schlägt
											Cinuru basierend auf dem Filmgeschmack des Nutzers regelmäßig interessante
											Filme vor. Außerdem werden Nutzer auf Kinostarts zu Filmen ihrer Merkliste
											aufmerksam gemacht.
										</StyledP>
										<br />
									</div>
									<Button
										color="white"
										size="lg"
										href="https://kino.cinuru.com"
										target="_blank"
										style={{ backgroundColor: 'rgb(79, 146, 247)', color: 'white' }}
									>
										Cinuru Website
									</Button>

									<Button
										justIcon
										color="white"
										simple
										href="https://apps.apple.com/us/app/cinuru-dein-kino-und-du/id1281946162?itsct=apps_box_link&itscg=30200"
										target="_blank"
									>
										<i class="fab fa-app-store-ios"></i>
									</Button>
									<Button
										justIcon
										color="white"
										simple
										href="https://play.google.com/store/apps/details?id=com.cinuru.app"
										target="_blank"
									>
										<i class="fab fa-google-play"></i>
									</Button>
								</GridItem>
							</GridContainer>
						</div>
					</div>
				</div>
				{/* Carousel 1 END */}
				{/* Carousel 3 START */}
				<div style={{ margin: '20px' }}>
					<div className={classes.pageHeader} style={{ backgroundImage: `url("${dg3}")` }}>
						<div className={classes.container}>
							<GridContainer style={{ margin: '0 50px' }}>
								<GridItem
									xs={12}
									sm={7}
									md={7}
									className={classNames(classes.mlAuto, classes.textRight)}
								>
									<StyledHeader1 className={classes.title}>Cinema-CRM</StyledHeader1>
									<div style={{ color: whiteColor }}>
										<StyledP>
											Das Cinuru-Dashboard ermöglicht es Kinos Einblicke in das Besuchsverhalten
											ihrer Nutzer zu erhalten und personalisierte Push-Notifications und E-Mails zu
											versenden.
										</StyledP>
										<StyledP>
											Das Dashboard ist als moderne React-Single-Page-Application umgesetzt.
										</StyledP>
										<br />
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</div>
				</div>
				{/* Carousel 3 END */}
				{/* Carousel 2 START */}
				<div>
					<div className={classes.pageHeader} style={{ backgroundImage: `url("${dg2}")` }}>
						<div className={classes.container}>
							<GridContainer style={{ margin: '0 50px' }}>
								<GridItem
									xs={12}
									sm={6}
									md={6}
									className={classNames(classes.mlAuto, classes.textRight)}
								>
									<StyledHeader1 className={classes.title}>
										Modulares App-Baukasten-System
									</StyledHeader1>
									<div style={{ color: whiteColor }}>
										<StyledP>
											Warum das Rad neu erfinden? Unser proprietäres App-Baukasten-System ermöglicht
											es umfangreiche Apps innerhalb kürzester Zeit zu implementieren.
										</StyledP>
										<StyledP>
											Dazu sind wichtige Komponenten wie z.B. Login, Registrierung,
											Push-Benachrichtigungen, etc. bereits als App und Backend-Modul implementiert.
											Wir können direkt beginnen Ihre Funktionen umzusetzen.
										</StyledP>
									</div>
									{/*<br />
									<h6>Connect with us on:</h6>
									 <div>
										<Button color="white" simple size="lg" justIcon>
											<i className="fab fa-twitter" />
										</Button>
										<Button color="white" simple size="lg" justIcon>
											<i className="fab fa-facebook-square" />
										</Button>
										<Button color="white" simple size="lg" justIcon>
											<i className="fab fa-google-plus-g" />
										</Button>
										<Button color="white" simple size="lg" justIcon>
											<i className="fab fa-instagram" />
										</Button>
									</div> */}
								</GridItem>
							</GridContainer>
						</div>
					</div>
				</div>
				{/* Carousel 2 END */}
			</Carousel>
		</div>
	);
}
