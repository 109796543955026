/*eslint-disable*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';

// sections for this page
import KPISection from './Sections/KPISection.js';
import TechnologiesSection from './Sections/TechnologiesSection.js';
import WhatWeDoSection from './Sections/WhatWeDoSection.js';
import CineplexSection from './Sections/CineplexSection.js';
import ReferenceProjectsSection from './Sections/ReferenceProjectsSection.js';
import SectionTeams from './Sections/SectionTeams.js';
import NewsletterSection from './Sections/NewsletterSection.js';

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';

import MainContainer from '../../customComponents/MainContainer';
import bg0 from 'assets/img/bg0.png';
const useStyles = makeStyles(presentationStyle);

export const HomePage = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = useStyles();
	return (
		<div>
			<Parallax style={{ color: 'black', marginTop: '10vh' }}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem>
							<div
								className={classes.brand}
								style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}
							>
								<h1 style={{ color: 'black', fontSize: '4rem' }}>Wir bauen Ihre App.</h1>
								<h3 className={classes.title} style={{ color: 'black', fontSize: '1.5rem' }}>
									State-of-the-Art Apps und Webanwendungen
								</h3>
								<img
									src={bg0}
									style={{
										width: '90%',
										maxWidth: '800px',
										margin: 'auto',
									}}
								/>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</Parallax>
			<MainContainer>
				<WhatWeDoSection id="what-we-do" />
				<ReferenceProjectsSection id="projects" />
				<CineplexSection id="case-study" />

				<TechnologiesSection id="technology" />
				<KPISection />
				{/*<SectionTeams id="team" />*}
				{/* <NewsletterSection /> */}
			</MainContainer>
		</div>
	);
};
