/*eslint-disable*/
import React from 'react';
import styled from 'styled-components';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import Extension from '@material-ui/icons/Extension';
import ChildFriendly from '@material-ui/icons/ChildFriendly';
import WatchLater from '@material-ui/icons/WatchLater';
import Code from '@material-ui/icons/Code';
import FormatPaint from '@material-ui/icons/FormatPaint';
import Dashboard from '@material-ui/icons/Dashboard';
import ViewCarousel from '@material-ui/icons/ViewCarousel';

import MainContainer from 'customComponents/MainContainer';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';

import iphone from 'assets/img/sections/iphone.png';
import bpLeft from 'assets/img/cineplex3d/BonusProgram_Left.png';
import bpRight from 'assets/img/cineplex3d/BonusProgram_Right.png';
import movieDetailLeft from 'assets/img/cineplex3d/MovieDetail_Left.png';
import bpmovieDetailRight from 'assets/img/cineplex3d/MovieDetail_Right.png';
import showtimesViewLeft from 'assets/img/cineplex3d/Showtimes_Left.png';
import ticketViewRight from 'assets/img/cineplex3d/Ticket_Right.png';

import Payment from '@material-ui/icons/Payment';
import PlayArrow from '@material-ui/icons/PlayArrow';
import LocalActivity from '@material-ui/icons/LocalActivity';

import Smartphone3 from '../customComponents/Smartphone3';

const useStyles = makeStyles(presentationStyle);

const sectionData = [
	{
		image: bpLeft,
		title: 'Bonusprogramm',
		data: [
			{
				title: undefined,
				description:
					'Mit dem Cineplex PLUS Bonusprogramm lassen sich Kinokunden durch wertvolle Prämien und Achievements an das jeweilige Kino effektiv binden. Neben diesen Belohnungen können besonders interessierte Filmfreunde Push-Notifications zum aktuellen Kinoprogramm erhalten oder Teil des Cineplex-Newsletters werden. Die gesamte App-Experience ist für alle Nachteulen natürlich auch im Darkmode verfügbar.',
				icon: Code,
			},
			// { title: 'Title2', description: 'description2', icon: Code },
		],
		description: '123123',
	},
	{
		image: bpmovieDetailRight,
		title: 'Trailer ansehen',
		data: [
			{
				title: undefined,
				description:
					'Filmfreunde haben natürlich die Möglichkeit, sich über interessante Filme zu informieren. Es ist es möglich, sich Trailer anzusehen, sich die Filmbeschreibungen durchzulesen, bereits gesehene Filme zu bewerten und bei Interesse Filme auf seine persönliche Merkliste zu setzen. So wird sichergestellt, dass sie in Zukunft keinen interessanten Film mehr verpassen.',
				icon: Code,
			},
			// { title: 'Title2', description: 'description2', icon: Code },
		],
		description: '123123',
	},
	{
		image: showtimesViewLeft,
		title: 'Entdecken',
		data: [
			{
				title: undefined,
				description:
					'Um bei der Masse an verfügbarem Filmmaterial den Überblick zu behalten, hilft unser Machine-Learning-basierter Filmempfehlungs-Algorithmus, der auf Basis von Nutzereingaben die passendsten Filme gut zugänglich auf dem Entdecken-Tab priorisiert. Soll es aber einfach mal ins Kino gehen, lässt sich das aktuelle Kinoprogramm auch simpel nach Datum und Attributen filtern, um die passende Vorstellung zu finden',
				icon: Code,
			},
			// { title: 'Title2', description: 'description2', icon: Code },
		],
		description: '123123',
	},
	{
		image: ticketViewRight,
		title: 'Tickets buchen',
		data: [
			{
				title: undefined,
				description:
					'Ist die passende Vorstellung in einem der fast 100 Cineplex-Kinos gefunden worden, sollte der unangenehme Teil möglichst angenehm vonstatten gehen - die Bezahlung. Hierzu reicht ein einfacher Klick auf die Spielzeit. Im Anschluss wird die Zahlung mittels aller gängiger Zahlungsmittel ermöglicht. Nach erfolgreichem Kauf, liegen die digitalen Tickets bequem in der App vor. Ist die Vorstellung vorbei, erhält der Nutzer die Möglichkeit, den gesehenen Film zu bewerten.',
				icon: Code,
			},
			// { title: 'Title2', description: 'description2', icon: Code },
		],
		description: '123123',
	},
];

export const CineplexDetailView = ({ ...rest }) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});

	const classes = useStyles();
	return (
		<MainContainer>
			{sectionData.map(({ image, picturePosition, title, data }) => (
				<Smartphone3 image={image} title={title} data={data} />
			))}
		</MainContainer>
	);
};
