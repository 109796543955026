/*eslint-disable*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import logo from 'assets/img/logo.png';
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';

const useStyles = makeStyles(presentationStyle);

const MainFooter = () => {
	const classes = useStyles();
	return (
		<Footer
			theme="white"
			content={
				<div>
					<div className={classes.left}>
						<a href="/" target="_blank" className={classes.footerBrand}>
							<img src={logo} style={{ width: '200px', opacity: '0.7', marginTop: '-5px' }} />{' '}
							<small>by Cinuru Research GmbH</small>
						</a>
					</div>
					<div className={classes.pullCenter}>
						<List className={classes.list}>
							<ListItem className={classes.inlineBlock}>
								<a
									href="https://kino.cinuru.com/impressum"
									target="_blank"
									className={classes.block}
								>
									Impressum
								</a>
							</ListItem>
							<ListItem className={classes.inlineBlock}>
								<a
									href="https://kino.cinuru.com/datenschutz"
									target="_blank"
									className={classes.block}
								>
									Datenschutz
								</a>
							</ListItem>
							<ListItem className={classes.inlineBlock}>
								<a href="https://kino.cinuru.com" className={classes.block}>
									Cinuru Research GmbH
								</a>
							</ListItem>
						</List>
					</div>
					{/* <div className={classes.rightLinks}>
						<ul>
							<li>
								<Button
									href="https://twitter.com/CreativeTim?ref=creativetim"
									target="_blank"
									color="twitter"
									justIcon
									simple
								>
									<i className="fab fa-twitter" />
								</Button>
							</li>
							<li>
								<Button
									href="https://dribbble.com/creativetim?ref=creativetim"
									target="_blank"
									color="dribbble"
									justIcon
									simple
								>
									<i className="fab fa-dribbble" />
								</Button>
							</li>
							<li>
								<Button
									href="https://instagram.com/CreativeTimOfficial?ref=creativetim"
									target="_blank"
									color="instagram"
									justIcon
									simple
								>
									<i className="fab fa-instagram" />
								</Button>
							</li>
						</ul>
					</div> */}
				</div>
			}
		/>
	);
};

export default MainFooter;
