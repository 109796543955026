import React from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

// @material-ui icons
import PersonIcon from '@material-ui/icons/Person';
import StarIcon from '@material-ui/icons/Star';
import CloudIcon from '@material-ui/icons/Cloud';
import CodeIcon from '@material-ui/icons/Code';
import Apps from '@material-ui/icons/Apps';
import ViewDay from '@material-ui/icons/ViewDay';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import descriptionStyle from 'assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js';

const useStyles = makeStyles(descriptionStyle);

export default function KPISection() {
	const classes = useStyles();
	return (
		<div className={classes.section}>
			<div className={classes.container}>
				<div className={classes.features}>
					<GridContainer>
						<GridItem md={6} sm={6}>
							<InfoArea
								title="Über 500.000 Nutzer"
								description="Die von uns entwickelten Apps werden von über einer halben Million Nutzer pro Jahr genutzt."
								icon={PersonIcon}
								iconColor="primary"
								vertical={true}
							/>
						</GridItem>
						<GridItem md={6} sm={6}>
							<InfoArea
								title="Ø4.6 / 5"
								description="Bewertung unserer eigenen App Cinuru im Apple-AppStore."
								icon={StarIcon}
								iconColor="primary"
								vertical={true}
							/>
						</GridItem>
						<GridItem md={6} sm={6}>
							<InfoArea
								title="Über 99,9 % Erreichbarkeit"
								description="Können wir unseren Kunden auf Wunsch garantieren."
								icon={CloudIcon}
								iconColor="primary"
								vertical={true}
							/>
						</GridItem>
						<GridItem md={6} sm={6}>
							<InfoArea
								title="über 320.000 Zeilen Code"
								description="Haben wir seit unserer Gründung 2017 geschrieben."
								icon={CodeIcon}
								iconColor="primary"
								vertical={true}
							/>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
