import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
import Muted from 'components/Typography/Muted.js';

import teamsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js';

import code2 from 'assets/img/code2.jpg';

import avatarPaulo from 'assets/img/Paulo.png';
import avatarAndreas from 'assets/img/Andreas.png';
import avatarPascal from 'assets/img/Pascal.png';

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
	const classes = useStyles();
	return (
		<div className="cd-section" {...rest}>
			<div
				className={classes.team + ' ' + classes.section}
				style={{ backgroundImage: `url(${code2})` }}
			>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={8}
							md={8}
							className={classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter}
						>
							<h2 className={classes.title}>Unser Team</h2>
							<h5 className={classes.descriptionWhite}>
								Wir sind Entwickler aus Leidenschaft. Hier stellen wir uns vor.
							</h5>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={4} md={4}>
							<Card profile>
								<CardAvatar profile>
									<a href="#pablo" onClick={(e) => e.preventDefault()}>
										<img src={avatarPaulo} style={{ backgroundColor: 'white' }} alt="..." />
									</a>
								</CardAvatar>
								<CardBody>
									<h4 className={classes.cardTitle}>Paulo Ruhrländer</h4>
									<Muted>
										<h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
									</Muted>
									<p className={classes.description}>
										Gute Software zu nutzen macht einfach Spaß! Der Weg dahin ist oft weit. Mir
										macht es Spaß Sie dabei zu begleiten: Von ersten Workshops, über UX und UI bis
										zum fertigen Produkt.
									</p>
									<p className={classes.description}>
										Sie erreichen mich unter 0176 / 227 124 03 oder
										paulo.ruhrlaender@codesanssouci.com
									</p>
								</CardBody>
								<CardFooter profile className={classes.justifyContent}>
									<Button justIcon round color="success" href="tel:+4917622712403">
										<i className="fas fa-phone" />
									</Button>
									<Button
										justIcon
										round
										color="twitter"
										href="mailto:paulo.ruhrlaender@codesanssouci.com"
									>
										<i className="fas fa-envelope" />
									</Button>
									<Button
										justIcon
										round
										color="linkedin"
										href="https://www.linkedin.com/in/paulo-ruhrlaender/"
									>
										<i className="fab fa-linkedin-in" />
									</Button>
								</CardFooter>
							</Card>
						</GridItem>

						<GridItem xs={12} sm={4} md={4}>
							<Card profile>
								<CardAvatar profile>
									<a href="#pablo" onClick={(e) => e.preventDefault()}>
										<img src={avatarAndreas} style={{ backgroundColor: 'white' }} alt="..." />
									</a>
								</CardAvatar>
								<CardBody>
									<h4 className={classes.cardTitle}>Dr. Andreas Beyer</h4>
									<Muted>
										<h6 className={classes.cardCategory}>Full-Stack Developer</h6>
									</Muted>
									<p className={classes.description}>
										Schlechte Software zu nutzen macht, wie Paulo angedeutet hat, eben keinen Spass.
										Das habe ich oft am eigenen Leib erfahren müssen, also wollte ich es besser
										machen und bin mit aufs Internetpferd aufgesprungen. Ich liebe es komplizierte
										Dinge mittels Code zu lösen.
									</p>
								</CardBody>
								<CardFooter profile className={classes.justifyContent}>
									<Button
										justIcon
										round
										color="twitter"
										href="mailto:andreas.beyer@codesanssouci.com"
									>
										<i className="fas fa-envelope" />
									</Button>
									<Button
										justIcon
										round
										color="linkedin"
										href="https://www.linkedin.com/in/andreas-beyer-9a0b4b87/"
									>
										<i className="fab fa-linkedin-in" />
									</Button>
								</CardFooter>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							<Card profile>
								<CardAvatar profile>
									<a href="#pablo" onClick={(e) => e.preventDefault()}>
										<img src={avatarPascal} style={{ backgroundColor: 'white' }} alt="..." />
									</a>
								</CardAvatar>
								<CardBody>
									<h4 className={classes.cardTitle}>Pascal Hanke</h4>
									<Muted>
										<h6 className={classes.cardCategory}>Full-Stack Developer & Design</h6>
									</Muted>
									<p className={classes.description}>
										Ich mag es, zu wissen woran ich bin. Code funktioniert oder eben nicht -
										einfach, klar und angenehm kompromisslos. Trotzdem bin ich ein Design-Freak:
										Solange es nicht gut aussieht, ist es nicht fertig.
									</p>
								</CardBody>
								<CardFooter profile className={classes.justifyContent}>
									<Button
										justIcon
										round
										color="twitter"
										href="mailto:pascal.hanke@codesanssouci.com"
									>
										<i className="fas fa-envelope" />
									</Button>
									<Button
										justIcon
										round
										color="linkedin"
										href="https://www.linkedin.com/in/pascal-hanke/"
									>
										<i className="fab fa-linkedin-in" />
									</Button>
								</CardFooter>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
