/*eslint-disable*/
import React from 'react';
import styled from 'styled-components';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js';

const Placeholder = styled.div`
	height: 500px;
	width: 100%auto;
`;

const useStyles = makeStyles(presentationStyle);

const MainContainer = ({ children }) => {
	const classes = useStyles();
	return (
		<div>
			<div className={classNames(classes.main, classes.mainRaised)} style={{ margin: '5rem 0' }}>
				{children}
			</div>
		</div>
	);
};

export default MainContainer;
