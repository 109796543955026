import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router';
import Header from 'customComponents/Header';
import Footer from 'customComponents/Footer';
import 'assets/scss/material-kit-pro-react.scss?v=1.10.0';

import { HomePage } from 'views/HomePage/HomePage.js';
import { CineplexDetailView } from 'views/CineplexDetailView.js';

export var history = createBrowserHistory();

ReactDOM.render(
	<Router history={history}>
		<Switch>
			<React.Fragment>
				<Header />
				<Route exact path="/" component={HomePage} />
				<Route path="/case-study" component={CineplexDetailView} />
				<Footer />
			</React.Fragment>
		</Switch>
	</Router>,
	document.getElementById('root')
);

/*!

=========================================================
* Material Kit PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
