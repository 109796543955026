import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router';
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';

import 'assets/scss/material-kit-pro-react.scss?v=1.10.0';

import { HomePage } from 'views/HomePage/HomePage.js';
import { CineplexDetailView } from 'views/CineplexDetailView.js';

export var history = createBrowserHistory();

const MainHeader = () => {
	return (
		<Header
			brand="Material Kit PRO React"
			links={<HeaderLinks dropdownHoverColor="info" />}
			fixed
			color="black"
			// changeColorOnScroll={{
			// 	height: 400,
			// 	color: 'primary',
			// }}
		/>
	);
};

export default MainHeader;
