import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Extension from '@material-ui/icons/Extension';
import ChildFriendly from '@material-ui/icons/ChildFriendly';
import WatchLater from '@material-ui/icons/WatchLater';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import featuresStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js';

import iphone from 'assets/img/sections/iphone.png';

const useStyles = makeStyles(featuresStyle);

export default function Smartphone3({ image, picturePosition, title, data = [], ...rest }) {
	console.log({ image, picturePosition, title, data });

	const classes = useStyles();

	return (
		<div className="cd-section" {...rest}>
			<div className={classes.container}>
				<div className={classes.features3}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={6}>
							<div className={classes.phoneContainer}>
								<img src={image} alt="..." />
							</div>
						</GridItem>
						<GridItem xs={12} sm={6} md={6}>
							<h2 className={classes.title}>{title}</h2>
							{data.map(({ title, description, icon, iconColor = 'secondary' }) => (
								<InfoArea
									className={classes.infoArea}
									icon={icon}
									title={title}
									description={description}
									iconColor={iconColor}
								/>
							))}
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
