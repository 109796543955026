/* eslint-disable */
import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Muted from 'components/Typography/Muted.js';

import projectsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js';

import AppsImage from 'assets/img/smartphone.png';
import ApiImage from 'assets/img/harddrive.png';
import DashboardImage from 'assets/img/dashboard.png';

const useStyles = makeStyles(projectsStyle);

export default function WhatWeDoSection({ ...rest }) {
	const classes = useStyles();
	return (
		<div className="cd-section" {...rest}>
			<div className={classes.projects}>
				<div className={classes.container}>
					<GridContainer>
						<GridItem
							xs={12}
							sm={8}
							md={8}
							className={classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter}
						>
							<Muted>
								<h6>Unsere Leistungen</h6>
							</Muted>
							<h2 className={classes.title}>Wir sind hier, damit ...</h2>
							<h5 className={classes.description}>
								... Sie Ihre Softwarprojekte in guten Händen wissen. Mit über 15 Jahren Erfahrung
								entwickeln wir Ihre Apps oder Webanwendungen zuverlässig.
							</h5>
							<div className={classes.sectionSpace} />
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={4} md={4}>
							<Card plain className={classes.card2}>
								<CardHeader image plain noShadow>
									<img src={AppsImage} alt="Apps" />
									<div
										className={classes.coloredShadow}
										style={{
											backgroundImage: `url('${AppsImage}')`,
											opacity: '1',
										}}
									/>
								</CardHeader>
								<CardBody plain>
									<h4 className={classes.cardTitle}>App-Entwicklung</h4>

									<h6 className={classes.description}>React-Native-Experten seit 2016</h6>
									<p className={classes.description + ' ' + classes.marginTop20}>
										App-Entwicklung ist der Hauptfokus unseres Schaffens. Wir entwickeln
										plattformübergreifend (Android & iOS) mit React-Native. So erstellen wir echte,
										native Apps mit deutlich geringerem Entwicklungsaufwand. Wir bieten auch
										Integrationen von nativen SDKs und Komponenten an.
									</p>
								</CardBody>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							<Card plain className={classes.card2}>
								<CardHeader image plain noShadow>
									<img src={DashboardImage} alt="Apps" />
								</CardHeader>

								<CardBody plain>
									<h4 className={classes.cardTitle}>Webanwendungen & Dashboards</h4>

									<h6 className={classes.description}>Wir entwickeln komplexe Webanwendungen</h6>
									<p className={classes.description + ' ' + classes.marginTop20}>
										Sie wünschen eine App, die sowohl nativ als auch als Webanwendung funktioniert?
										Wir können Ihnen helfen. Auch andere Webanwendungen, wie z.B. Auswertungs- oder
										Verwaltungsdashboards können wir Ihnen erstellen.
									</p>
								</CardBody>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							<Card plain className={classes.card2}>
								<CardHeader image plain noShadow>
									<img src={ApiImage} alt="Apps" />
								</CardHeader>

								<CardBody plain>
									<h4 className={classes.cardTitle}>APIs & Datenbanken</h4>

									<h6 className={classes.description}>
										Unsere APIs verarbeiten viele 100.000 Requests pro Tag
									</h6>
									<p className={classes.description + ' ' + classes.marginTop20}>
										Damit Ihre Daten sicher sind und Ihre Anwendungen zuverlässig funktionieren,
										entwickeln wir solide Backends & APIs. Neben Datenbaken und APIs bieten wir auch
										weitere Services wie Newsletterversand oder Push-Notifications an.
									</p>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}
