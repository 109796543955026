import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
// @material-ui icons
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// images
import GraphQL from 'assets/img/graphql.svg';
import PogstgresSVG from 'assets/img/postgres.svg';
import ServerlessSVG from 'assets/img/serverless.svg';

import overviewStyle from 'assets/jss/material-kit-pro-react/views/presentationSections/overviewStyle.js';
import { whiteColor } from 'assets/jss/material-kit-pro-react';

const useStyles = makeStyles(overviewStyle);

const createIconGenerator = (fontAwesomeName) => {
	return <i class={`fab ${fontAwesomeName}`} style={{ fontSize: 60 }} />;
};

export default function TechnologiesSection({ ...rest }) {
	const ReactIcon = React.useMemo(() => () => createIconGenerator('fa-react'));
	const AWSIcon = React.useMemo(() => () => createIconGenerator('fa-aws'));
	const NodeIcon = React.useMemo(() => () => createIconGenerator('fa-node-js'));
	const PythonIcon = React.useMemo(() => () => createIconGenerator('fa-python'));

	const GraphqlIcon = React.useMemo(() => () => <img src={GraphQL} height="60px" />);
	const PostgresIcon = React.useMemo(() => () => <img src={PogstgresSVG} height="60px" />);
	const ServerlessIcon = React.useMemo(() => () => <img src={ServerlessSVG} height="60px" />);
	const classes = useStyles();
	return (
		<div className={classes.section} {...rest}>
			<div
				className={classes.features5}
				style={{
					backgroundImage: `url(${require('assets/img/assets-for-demo/features-2.jpg').default})`,
				}}
			>
				<GridItem md={8} className={classNames(classes.mlAuto, classes.mrAuto)}>
					<h2 className={classes.title}>Unsere Technologien</h2>
				</GridItem>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem sm={4} className={classes.featuresShow}>
							<InfoArea
								title="React Native"
								description={
									<p>
										React Native ermöglicht es uns, echte native Apps plattformübergreifend zu
										entwickeln. So entstehen hoch-performante Anwendungen, die sich natürlich in die
										UX der jeweiligen Plattform einfügen. Da 90% des Codes zwischen den Plattformen
										geshared werden kann, wird die Entwicklung enorm beschleunigt.
									</p>
								}
								icon={ReactIcon}
								iconColor="gray"
								vertical={true}
							/>
						</GridItem>

						<GridItem sm={4} className={classes.featuresShow}>
							<InfoArea
								title="GraphQL"
								description={
									<p>
										Für unsere APIs nutzen wir neben dem weit verbreiteten REST-Standard überwiegend
										GraphQL-APIs. Mit diesem neuen Standard lassen sich sehr flexible APIs schaffen,
										die von den verschiedenen Clients (Apps, Webanwendungen, Automatisierungen)
										angesteuert werden können.
									</p>
								}
								icon={GraphqlIcon}
								iconColor="gray"
								vertical={true}
							/>
						</GridItem>
						<GridItem sm={4} className={classes.featuresShow}>
							<InfoArea
								title="AWS"
								description={
									<p>
										Unsere Backends sind Cloud-Native! Wir deployen überwiegend auf AWS. Dies
										ermöglicht es uns hoch performante und hochverfügbare Backends zu liefern. Ein
										besonderer Vorteil ist die mühelose Skalierbarkeit. So wächst Ihre Infrastruktur
										mit Ihren Nutzern.
									</p>
								}
								icon={AWSIcon}
								iconColor="gray"
								vertical={true}
							/>
						</GridItem>
						<GridItem sm={3} className={classes.featuresShow}>
							<InfoArea
								title="PostgreSQL"
								description={
									<p>
										Als Master-Datenbanken setzten wir überwiegend SQL-Datenbanken ein - vor allem
										PostgreSQL. Diese OpenSource-Datenbank ist sehr verlässlich und wird seit
										Jahrzehnten in Enterprise-Systemen eingesetzt. Oft kombinieren wir diese
										Datenbanken mit NoSQL und In-Memory Datenbanken (Redis, MongoDB) zur Steigerung
										der Performance
									</p>
								}
								icon={PostgresIcon}
								iconColor="gray"
								vertical={true}
							/>
						</GridItem>

						<GridItem sm={3} className={classes.featuresShow}>
							<InfoArea
								title="Node.js"
								description={
									<p>
										Node.js erlaubt es uns Javascript und Typescript im Backend einzusetzen. So
										können wir Logik zwischen Front- und Backend teilen und Entwicklungen
										beschleunigen. Der Einsatz von Javascript im Backend ermöglicht es uns, die hohe
										Innovationsdichte in diesem Ökosystem auszunutzen.
									</p>
								}
								icon={NodeIcon}
								iconColor="gray"
								vertical={true}
							/>
						</GridItem>

						<GridItem sm={3} className={classes.featuresShow}>
							<InfoArea
								title="Serverless Framework"
								description={
									<p>
										Viele unserer Backend-Services werden serverless deployt. D.h. der
										Cloud-Provider übernimmt die Skalierung vollumfänglich. So kann auf Lastspitzen
										innerhalb von Sekunden reagiert werden.
									</p>
								}
								icon={ServerlessIcon}
								iconColor="gray"
								vertical={true}
							/>
						</GridItem>
						<GridItem sm={3} className={classes.featuresShow}>
							<InfoArea
								title="Python / R"
								description={
									<p>
										Wir haben große Expertise im Bereich Machine-Learning und in der
										Recommender-Entwicklung, speziell für den Kinobereich. Unsere selbst-lernenden
										Modelle bauen wir dabei überwiegend in Python, zum Teil auch in R.
									</p>
								}
								icon={PythonIcon}
								iconColor="gray"
								vertical={true}
							/>
						</GridItem>
						<GridItem sm={12} className={classes.featuresShow}>
							<h3 style={{ color: whiteColor }}>Und viele Weitere</h3>

							<p>
								Apollo, React JS, Redis, Typescript, Linux, Apache, NginX, Ubuntu, Shell-Scripting,
								Yarn, NumPy, Scikit-learn, LensKit, Bitrise, Firebase, OAuth, Material-ui,
								styled-componets, Sketch, MJML, REST, GoogleAd Manager, Selligent, Java, Swift,
								Objective-C, Jest, Babel, Webpack, Native Modules
							</p>
						</GridItem>
					</GridContainer>
				</div>
			</div>
			{/* <div className={classes.sectionTestimonials}>
				<div className={classes.container}>
					<div className={classes.ourClients}>
						<h2>Partner, Förderer und Unterstützer</h2>
						<GridContainer justify="center">
							<GridItem md={3} sm={3}>
								<img src={Vodafone} alt="vodafone" />
							</GridItem>
							<GridItem md={3} sm={3}>
								<img src={Microsoft} alt="microsoft" />
							</GridItem>
							<GridItem md={3} sm={3}>
								<img src={Harvard} alt="harvard" />
							</GridItem>
							<GridItem md={3} sm={3}>
								<img src={Standford} alt="stanford" />
							</GridItem>
						</GridContainer>
					</div>
				</div> 
			</div>*/}
		</div>
	);
}
