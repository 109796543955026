/* eslint-disable */
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react components for routing our app without refresh

import Button from '../CustomButtons/Button.js';
import { HashLink } from 'react-router-hash-link';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// @material-ui/icons
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';

import styles from 'assets/jss/material-kit-pro-react/components/headerLinksStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
	const easeInOutQuad = (t, b, c, d) => {
		t /= d / 2;
		if (t < 1) return (c / 2) * t * t + b;
		t--;
		return (-c / 2) * (t * (t - 2) - 1) + b;
	};

	const smoothScroll = (e, target) => {
		if (window.location.pathname === '/sections') {
			var isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
			if (isMobile) {
				// if we are on mobile device the scroll into view will be managed by the browser
			} else {
				e.preventDefault();
				var targetScroll = document.getElementById(target);
				scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
			}
		}
	};
	const scrollGo = (element, to, duration) => {
		var start = element.scrollTop,
			change = to - start,
			currentTime = 0,
			increment = 20;

		var animateScroll = function () {
			currentTime += increment;
			var val = easeInOutQuad(currentTime, start, change, duration);
			element.scrollTop = val;
			if (currentTime < duration) {
				setTimeout(animateScroll, increment);
			}
		};
		animateScroll();
	};
	var onClickSections = {};

	const { dropdownHoverColor } = props;
	const classes = useStyles();
	return (
		<List className={classes.list + ' ' + classes.mlAuto}>
			<ListItem className={classes.listItem}>
				<HashLink to="#what-we-do" className={classes.dropdownLink}>
					Leistungen
				</HashLink>
			</ListItem>
			<ListItem className={classes.listItem}>
				<HashLink to="#projects" className={classes.dropdownLink}>
					Projekte
				</HashLink>
			</ListItem>
			<ListItem className={classes.listItem}>
				<HashLink to="#case-study" className={classes.dropdownLink}>
					Case-Study
				</HashLink>
			</ListItem>
			<ListItem className={classes.listItem}>
				<HashLink to="#technology" className={classes.dropdownLink}>
					Technologien
				</HashLink>
			</ListItem>
			<ListItem className={classes.listItem}>
				<HashLink to="#team" className={classes.dropdownLink}>
					Team
				</HashLink>
			</ListItem>

			<ListItem className={classes.listItem}>
				<div className={classes.dropdownLinkTelephone}>
					<Button
						justIcon
						round
						color="white"
						href="tel:+4917622712403"
						style={{ backgroundColor: 'rgb(79, 146, 247)', color: 'white' }}
					>
						<PhoneRoundedIcon />
					</Button>
					<div className={classes.phoneNumber}>+49 176/227 124 03</div>
				</div>
			</ListItem>
		</List>
	);
}

HeaderLinks.defaultProps = {
	hoverColor: 'primary',
};

HeaderLinks.propTypes = {
	dropdownHoverColor: PropTypes.oneOf([
		'dark',
		'primary',
		'info',
		'success',
		'warning',
		'danger',
		'rose',
		'white',
		'blue',
		'indigo',
		'purple',
		'pink',
		'red',
		'orange',
		'yellow',
		'green',
		'teal',
		'cyan',
		'white',
		'gray',
		'gray-dark',
	]),
};
